import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, type Theme } from '@mui/material';
import PromptItem, { type PromptItemType } from '../PromptItem';
import { type PromptsMap } from '..';
import { makeStyles } from 'tss-react/mui';

const useStyles: any = makeStyles()((theme: Theme) => ({
  promptItemsContainer: {
    padding: theme.spacing(2),
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  dialogActions: {
    justifyContent: 'start'
  }
}));

const PromptsListContent = ({
  prompts,
  deletePrompt,
  handleEditMode,
  handleClose,
  setInputText
}: {
  prompts: PromptsMap
  deletePrompt: (id: string) => void
  handleEditMode: (id?: string | null, open?: boolean, openModal?: boolean) => void
  handleClose: () => void
  setInputText: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const { classes } = useStyles();
  return (
    <>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'baseline' },
        justifyContent: 'space-between'
      }}>
        Seleziona uno dei tuoi prompt preferiti
        <Button sx={{ ml: { sm: 2 } }} onClick={() => { handleEditMode(null, true, true); }}>Crea nuovo</Button>
      </DialogTitle>
      <DialogContent className={classes.promptItemsContainer} dividers>
        {Object.values(prompts).sort((a: PromptItemType, b: PromptItemType) => {
          return a.date <= b.date ? 1 : -1;
        }).map((item) => (
          <PromptItem item={item} key={item.id} deletePrompt={deletePrompt} handleEditMode={handleEditMode} setInputText={setInputText} />
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color="warning" autoFocus onClick={handleClose}>
          Annulla
        </Button>
      </DialogActions>
    </>
  );
};

export default PromptsListContent;
