import React from 'react';
import { Backdrop, type BackdropProps } from '@mui/material';
import { useTheme } from '@emotion/react';

const CustomBackdrop = (props: BackdropProps): JSX.Element => {
  const theme: any = useTheme();

  return <Backdrop
        { ...props}
        sx={{
          left: { xs: 0, sm: theme.constants.drawerWidth },
          top: theme.constants.appbarHeight
        }}
    />;
};

export default CustomBackdrop;
