import { type PromptItemType } from '../PromptItem';
import { type PromptsMap } from '..';

interface State {
  prompts: PromptsMap
  promptToEdit?: string | null
  load: boolean
};

interface ReducerAction {
  type: 'LIST' | 'INSERT' | 'UPDATE' | 'DELETE' | 'STARTUPDATE' | 'RESETUPDATE'
  data?: {
    items?: PromptItemType[]
    id?: string
    item?: PromptItemType
    load?: boolean
  }
};

const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case 'LIST': {
      if ((action.data?.items) == null) {
        return state;
      }
      const prompts = action.data.items.reduce((acc: PromptsMap, item: PromptItemType) => {
        if (acc[item.id] === undefined) {
          acc[item.id] = item;
        }
        return acc;
      }, {});
      return {
        ...state,
        promptToEdit: null,
        prompts,
        load: false
      };
    }
    case 'DELETE': {
      if (!action.data?.id) {
        return state;
      }
      const prompts = state.prompts;
      Reflect.deleteProperty(prompts, action.data.id);
      return {
        ...state,
        promptToEdit: null,
        prompts,
        load: false
      };
    }
    case 'INSERT': {
      if (!action.data?.item?.id) {
        return state;
      }
      const prompts = state.prompts;
      prompts[action.data.item.id] = action.data.item;
      return {
        ...state,
        promptToEdit: null,
        prompts,
        load: false
      };
    }
    case 'UPDATE': {
      if (!action.data?.item?.id || state.prompts[action.data?.item?.id] === undefined) {
        return state;
      }
      const prompts = state.prompts;
      prompts[action.data.item.id] = action.data.item;
      return {
        ...state,
        promptToEdit: null,
        prompts,
        load: false
      };
    }
    case 'STARTUPDATE': {
      if (!action.data?.id) {
        return state;
      }
      return {
        ...state,
        promptToEdit: action.data.id,
        load: false
      };
    }
    case 'RESETUPDATE': {
      return {
        ...state,
        promptToEdit: null,
        load: false
      };
    }
  }
  return state;
};

export default reducer;
