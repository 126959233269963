export const typography = {
  fontFamily: 'Titillium Web',
  subtitle2: {
    fontSize: '0.75rem',
    lineHeight: '1.5rem'
  },
  body2: {
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  h6: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem'
  }
};
