import React, { type ReactNode, useCallback } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Box } from '@mui/material';

interface ICopyCodeButton {
  children: ReactNode
}

export const CopyCodeButton = ({ children }: ICopyCodeButton): JSX.Element => {
  const textInput = React.useRef<HTMLDivElement>(null);
  const [copied, setCopied] = React.useState(false);

  const onExit = (): void => {
    setCopied(false);
  };

  const onCopy = useCallback((): void => {
    setCopied(true);
    if (textInput.current !== null && textInput?.current.textContent !== null) { navigator.clipboard.writeText(textInput.current.textContent); };
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, []);

  const hasCodeTag = React.Children.toArray(children).some(child => {
    if (
      React.isValidElement(child) &&
      child.type === 'pre' &&
      React.Children.toArray(child.props.children).some(innerChild => {
        return React.isValidElement(innerChild) && innerChild.type === 'code' && innerChild.props?.className?.indexOf('hljs') >= 0;
      })
    ) {
      return true;
    }
    return false;
  });

  return (hasCodeTag
    ? <Box
            ref={textInput}
            onMouseLeave={onExit}
            bgcolor="#2b2b2b"
        >
          <Box color='#EFE0B9' display="flex" justifyContent="right" pr={1} pt={1}>
            <Box p={1} border={1} borderColor="#EFE0B9">
            {copied
              ? <p>Copiato</p>
              : <IconButton onClick={onCopy} sx={{ p: 0 }}>
                <ContentCopyIcon sx={{ color: '#EFE0B9' }} fontSize="small" />
            </IconButton> }
            </Box>
          </Box>
          {children}
      </Box>
    : <>{children}</>);
};
