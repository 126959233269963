import React from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import appConfig from 'appConfig';
import { MainPage } from 'MainPage';
import { AppBar, Box, Button, Container, Grid, Link, Paper, type Theme, ThemeProvider, Toolbar, Typography } from '@mui/material';
import theme from 'theme/theme';
import './App.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppProvider } from 'context/App';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import CompanyElement from 'components/CompanyElement';

const useStyles: any = makeStyles()((theme: Theme) => ({
  companiesContainer: {
    overflowY: 'auto',
    height: 'calc(100% - 64px)'
  },
  companyElement: {
    flexBasis: '50%',
    [theme.breakpoints.up('md')]: {
      flexBasis: '33.33%'
    }
  }
}));

appConfig.initConfig();

function GradientLine (): JSX.Element {
  return (
    <div
      style={{
        bottom: 0,
        width: '100%',
        height: 3,
        background: 'transparent linear-gradient(270deg, #20A537 0%, #0589C9 100%) 0% 0% no-repeat padding-box'
      }}
    />
  );
}

function AuthPage ({
  authStatus
}: {
  authStatus: string
}): JSX.Element {
  const { classes } = useStyles();

  const handleOffice365 = (identityProvider: string): void => {
    async function loginFunction (): Promise<void> {
      const clientId = process.env.REACT_APP_APP_CLIENT_ID;
      const address = process.env.REACT_APP_SENTRY_ENV === 'prod' ? 'https://chatgpt360.digital360.it' : 'https://chatgpt360.dev.ai.digital360.it';
      window.location.href = `https://chatgpt360.auth.${process.env.REACT_APP_REGION}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${address}&state=STATE&scope=aws.cognito.signin.user.admin+openid+email+profile&identity_provider=${identityProvider}`;
    }

    loginFunction();
  };

  const handleOffice365Digital = (): void => {
    handleOffice365('Digital360O365');
  };

  const handleOffice365Cryptonet = (): void => {
    handleOffice365('CryptonetSSO');
  };

  const handleOffice365Methodos = (): void => {
    handleOffice365('MethodosSSO');
  };

  const handleOffice365ICTLab = (): void => {
    handleOffice365('ICTLabSSO');
  };

  const handleOffice365DigitalAttitude = (): void => {
    handleOffice365('DigitalAttitudeSSO');
  };

  const handleOffice365InsideComunicazione = (): void => {
    handleOffice365('InsideComunicazioneSSO');
  };

  const handleOffice365Imageware = (): void => {
    handleOffice365('ImagewareSSO');
  };

  const handleOffice365SmartOperations = (): void => {
    handleOffice365('SmartOperationsSSO');
  };

  const handleOffice365Meridiana = (): void => {
    handleOffice365('MeridianaSSO');
  };

  const handleGoogle = (): void => {
    async function loginFunction (): Promise<void> {
      const clientId = process.env.REACT_APP_APP_CLIENT_ID;
      const address = process.env.REACT_APP_SENTRY_ENV === 'prod' ? 'https://chatgpt360.digital360.it' : 'https://chatgpt360.dev.ai.digital360.it';
      const identityProvider = 'Google';
      const url = `https://chatgpt360.auth.${process.env.REACT_APP_REGION}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${address}&state=STATE&scope=aws.cognito.signin.user.admin+openid+email+profile&identity_provider=Google&idp_identifier=${identityProvider}`;
      window.location.href = url;
    }

    loginFunction();
  };

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar>
            <Box display="flex" width={{ xs: 133, sm: 198 }} mr={2} >
              <Logo />
            </Box>
            <img
              src="https://cdnd360.it/networkdigital360/networkdigital360_logo_mail.png"
              alt="networkdigital360"
              style={{ height: '24px', maxWidth: '100%', margin: 'auto 0 auto auto' }} />
        </Toolbar>
        <GradientLine />
      </AppBar>
      <Box className={classes.companiesContainer}>
        { authStatus !== 'configuring'
          ? <Container maxWidth="lg" >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              align="center"
              color="#424242"
              fontFamily="Titillium Web"
              fontWeight="600"
              fontSize="24px"
              paddingTop="64px"
              paddingBottom="32px"
            >
              Accedi a ChatGPT360
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Paper sx={{
              border: '1px solid #e0e0e0',
              marginLeft: '10px',
              marginRight: '10px'
            }}
              variant="outlined">
              <Box px={2} py={1} display="flex" flexWrap="wrap" component="ul">
                <Box className={classes.companyElement} component="li">ICT&Strategy</Box>
                <Box className={classes.companyElement} component="li">Digital360</Box>
                <Box className={classes.companyElement} component="li">P4I</Box>
                <Box className={classes.companyElement} component="li">FPA</Box>
                <Box className={classes.companyElement} component="li">ServicePro</Box>
                <Box className={classes.companyElement} component="li">Digital Sales</Box>
              </Box>
              <div style={{ border: '1px solid #e0e0e0' }} />
              <Button
                fullWidth
                onClick={handleOffice365Digital}
                startIcon={<img src="office.png" alt="office" />}
                sx={{
                  justifyContent: 'flex-start',
                  textTransform: 'capitalize',
                  paddingLeft: '18px',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                <Typography
                  color="#424242"
                  fontFamily="Titillium Web"
                  fontWeight="600"
                  fontSize="16px"
                >
                  Accedi con Office365
                </Typography>
                <ChevronRightIcon htmlColor={theme.palette.primary.main} sx={{ marginLeft: 'auto' }}/>
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} mb={3} order={{ xs: 10, md: 'unset' }}>
            <Paper sx={{
              border: '1px solid #e0e0e0',
              marginLeft: '10px',
              marginRight: '10px'
            }}
            variant="outlined">
              <Box px={2} py={1} display="flex" flexWrap="wrap" component="ul">
                <Box className={classes.companyElement} component="li">CTMobi</Box>
                <Box className={classes.companyElement} component="li">Delmonte</Box>
              </Box>
              <div style={{ border: '1px solid #e0e0e0' }} />
              <Button
                fullWidth
                onClick={handleGoogle}
                startIcon={<img src="google.png" alt="google" />}
                sx={{
                  justifyContent: 'flex-start',
                  textTransform: 'capitalize',
                  paddingLeft: '18px',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                <Typography
                  color="#424242"
                  fontFamily="Titillium Web"
                  fontWeight="600"
                  fontSize="16px"
                >
                  Accedi con Google
                </Typography>
                <ChevronRightIcon htmlColor={theme.palette.primary.main} sx={{ marginLeft: 'auto' }}/>
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Paper sx={{
              border: '1px solid #e0e0e0',
              marginLeft: '10px',
              marginRight: '10px'
            }}
              variant="outlined">
              <Box px={2} py={1} display="flex" flexWrap="wrap" component="ul">
                <Box className={classes.companyElement} component="li">CryptoNet</Box>
              </Box>
              <div style={{ border: '1px solid #e0e0e0' }} />
              <Button
                fullWidth
                onClick={handleOffice365Cryptonet}
                startIcon={<img src="office.png" alt="office" />}
                sx={{
                  justifyContent: 'flex-start',
                  textTransform: 'capitalize',
                  paddingLeft: '18px',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                <Typography
                  color="#424242"
                  fontFamily="Titillium Web"
                  fontWeight="600"
                  fontSize="16px"
                >
                  Accedi con Office365
                </Typography>
                <ChevronRightIcon htmlColor={theme.palette.primary.main} sx={{ marginLeft: 'auto' }}/>
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Paper sx={{
              border: '1px solid #e0e0e0',
              marginLeft: '10px',
              marginRight: '10px'
            }}
              variant="outlined">
              <Box px={2} py={1} display="flex" flexWrap="wrap" component="ul">
                <Box className={classes.companyElement} component="li">Methodos</Box>
                <Box className={classes.companyElement} component="li">Accompany</Box>
              </Box>
              <div style={{ border: '1px solid #e0e0e0' }} />
              <Button
                fullWidth
                onClick={handleOffice365Methodos}
                startIcon={<img src="office.png" alt="office" />}
                sx={{
                  justifyContent: 'flex-start',
                  textTransform: 'capitalize',
                  paddingLeft: '18px',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
              >
                <Typography
                  color="#424242"
                  fontFamily="Titillium Web"
                  fontWeight="600"
                  fontSize="16px"
                >
                  Accedi con Office365
                </Typography>
                <ChevronRightIcon htmlColor={theme.palette.primary.main} sx={{ marginLeft: 'auto' }}/>
              </Button>
            </Paper>
          </Grid>
          <CompanyElement companies={['ICTLab']} loginFunction={handleOffice365ICTLab} classNameBox={classes.companyElement} />
          <CompanyElement companies={['DigitalAttitude']} loginFunction={handleOffice365DigitalAttitude} classNameBox={classes.companyElement} />
          <CompanyElement companies={['Inside Comunicazione']} loginFunction={handleOffice365InsideComunicazione} classNameBox={classes.companyElement} />
          <CompanyElement companies={['Imageware']} loginFunction={handleOffice365Imageware} classNameBox={classes.companyElement} />
          <CompanyElement companies={['SmartOperations']} loginFunction={handleOffice365SmartOperations} classNameBox={classes.companyElement} />
          <CompanyElement companies={['Meridiana']} loginFunction={handleOffice365Meridiana} classNameBox={classes.companyElement} />
        </Grid>
        <Box
          ml="10px"
          mr="10px"
          mt={3}
          mb={3}
        >
          <Link
            href="https://form.asana.com/?k=Bd8QLV462toEXDmYKMpB9g&d=516424511146860"
            target="_blank"
            underline="none"
          >
            Hai bisogno di supporto?
          </Link>
        </Box>
        </Container>
          : <Authenticator />
        }
      </Box>
    </>
  );
}

function AuthComponent (): JSX.Element {
  const { user, signOut, authStatus } = useAuthenticator();

  return (
    <>
      { authStatus !== 'authenticated' ? <AuthPage authStatus={authStatus} /> : <MainPage signOut={signOut} user={user} /> }
    </>
  );
}

function App (): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <AppProvider>
          <AuthComponent />
        </AppProvider>
      </Authenticator.Provider>
    </ThemeProvider>
  );
}

export default App;
