import React, { useEffect, useReducer, useCallback, useState } from 'react';
import Modal from 'components/Modal';
import { Button } from '@mui/material';
import { type PromptItemType } from './PromptItem';
import reducer from './reducer';
import PromptsListContent from './PromptsListContent';
import PromptsEditContent from './PromptsEditContent';
import PromptsInsertContent from './PromptsInsertContent';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import toast from 'react-hot-toast';

export type PromptsMap = Record<string, PromptItemType>;

export interface PromptsModalState {
  open: boolean
  editMode: boolean
  promptText?: string | undefined
};

const PromptsModal = ({
  modalState,
  setModalState,
  setInputText
}: {
  modalState: PromptsModalState
  setModalState: React.Dispatch<React.SetStateAction<any>>
  setInputText: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    prompts: {},
    promptToEdit: null,
    load: true
  });
  const { user } = useAuthenticator((context) => [context.user]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPrompts = async (): Promise<any> => {
      try {
        const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
        const result = await fetch(`${process.env.REACT_APP_PROMPT_ENDPOINT}/prompts`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const json = await result.json();
        if (!json.result) {
          throw new Error(json.message);
        }
        const items = json.result;
        dispatch({
          type: 'LIST',
          data: {
            items
          }
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
        captureException(err, {
          tags: {
            component: 'Prompts',
            action: 'getPrompts'
          },
          user
        });
        dispatch({
          type: 'LIST',
          data: {
            items: []
          }
        });
        setLoading(false);
      }
    };

    getPrompts();
  }, []);

  const handleClose = (): void => {
    setModalState({
      ...modalState,
      open: false,
      promptText: undefined
    });
  };

  /**
   * Elimina un prompt.
   * @param {string} id id del prompt da eliminare
   */
  const deletePrompt = async (id: string): Promise<any> => {
    setLoading(true);

    try {
      const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      const result = await fetch(`${process.env.REACT_APP_PROMPT_ENDPOINT}/prompts/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const json = await result.json();
      if (!json.result) {
        throw new Error(json.message);
      }
      dispatch({
        type: 'DELETE',
        data: {
          id
        }
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleClose();
      toast.error('Qualcosa è andato storto, riprova più tardi', {
        id: 'delete-prompt'
      });

      captureException(err, {
        tags: {
          component: 'Prompts',
          action: 'deletePrompt',
          idPrompt: id
        },
        user
      });
    }
  };

  /**
   * Gestisce l'apertura e la chiusura della finestra di editazione prompt
   */
  const handleEditMode = useCallback((id?: string | null, open?: boolean, modalOpen?: boolean) => {
    // Se viene passato un id, apro la finestra di modifica prompt
    if (id) {
      dispatch({
        type: 'STARTUPDATE',
        data: {
          id
        }
      });
      setModalState({
        ...modalState,
        editMode: true,
        promptText: undefined
      });
      return;
    }

    // Altrimenti apro/chiudo la finestra di inserimento prompt
    // e mi preoccupo anche di resettare l'id del prompt (non sto modificando)
    dispatch({
      type: 'RESETUPDATE'
    });
    setModalState({
      ...modalState,
      open: modalOpen,
      editMode: open,
      promptText: undefined
    });
  }, [modalState]);

  /**
   * Gestisce il loader
   * @param {boolean} load boolean
   */
  const handleLoad = (load: boolean): void => {
    setLoading(load);
  };

  return (
    <>
      <Button onClick={() => {
        setModalState({
          open: true,
          editMode: false
        });
      }}>I tuoi Prompt</Button>
      <Modal
        open={modalState.open}
      >
        { loading &&
          <Box
            position="absolute"
            width="100%"
            height="100%"
            zIndex={1}>
              <CircularProgress sx={{
                position: 'absolute',
                left: 'calc(50% - 25px)',
                top: 'calc(50% - 25px)'
              }}/>
          </Box>
        }
        {
          !modalState.editMode
            ? <PromptsListContent
            prompts={state.prompts}
            handleClose={handleClose}
            deletePrompt={deletePrompt}
            handleEditMode={handleEditMode}
            setInputText={setInputText}
          />
            : (state.promptToEdit && state.prompts[state.promptToEdit]
                ? (
          <PromptsEditContent
            prompt={state.prompts[state.promptToEdit]}
            handleEditMode={handleEditMode}
            dispatch={dispatch}
            handleLoad={handleLoad}
          />
                  )
                : <PromptsInsertContent
                  handleEditMode={handleEditMode}
                  dispatch={dispatch}
                  promptTextFromChat={modalState.promptText}
                  handleLoad={handleLoad}
              />)
        }
      </Modal>
    </>
  );
};

export default PromptsModal;
