import { palette } from 'theme/globals';

export const dialogtitle = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '24px',
        color: palette.grey[800],
        padding: 16
      }
    }
  }
};
