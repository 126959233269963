import React from 'react';
import { Toaster } from 'react-hot-toast';

const AppToaster = (): JSX.Element => {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        style: {
          color: '#FFF',
          maxWidth: 'none',
          borderRadius: 0
        },
        icon: null,
        error: {
          style: {
            background: '#B00020'
          }
        }
      }}
    />
  );
};

export default AppToaster;
