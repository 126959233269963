import React from 'react';
import Dialog from '@mui/material/Dialog';
import CustomBackdrop from 'components/CustomBackdrop';
import { useTheme } from '@emotion/react';

export default function CustomizedDialogs ({
  open,
  handleClose,
  children
}: {
  open: boolean
  handleClose?: () => void
  children: React.ReactNode
}): JSX.Element {
  const theme: any = useTheme();
  return (
    <Dialog
      onClose={(handleClose != null) ? handleClose : () => {}}
      aria-labelledby="customized-dialog-title"
      open={open}
      slots={{
        backdrop: CustomBackdrop
      }}
      PaperProps={{
        sx: {
          height: 380
        }
      }}
      sx={{
        left: { xs: 0, sm: theme.constants.drawerWidth },
        top: theme.constants.appbarHeight,
        zIndex: theme.zIndex.drawer - 1
      }}
      >
      {children}
    </Dialog>
  );
}
