import * as Sentry from '@sentry/react';
interface props {
  dsn: string
  tracesSampleRate: number
  integrations: Sentry.BrowserTracing[]
  environment: string
}

const sentryConfig: props = {
  dsn: 'https://93290a684c2a4929ac990fb0c9eabcd6@o234395.ingest.sentry.io/4505114929266688',
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_RATE as string),
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENV as string
};

export default sentryConfig;
