import React, { type MouseEventHandler, useContext, useState } from 'react';
import Cookies from 'js-cookie';

interface AppContextData {
  declaimerClosed: boolean
  closeDeclaimer: MouseEventHandler<HTMLButtonElement>
}

const appContext = React.createContext<AppContextData>({
  declaimerClosed: false,
  closeDeclaimer: () => {}
});

export const useAppContext = (): AppContextData => useContext(appContext);

const useAppProvider = (): AppContextData => {
  // Stato per determinare se il declaimer è stato chiuso, se non è stato chiuso bisogna bloccare la chat.
  const cookie = Cookies.get('disclaimer_viewed');
  const [declaimerClosed, setDeclaimerClosed] = useState<boolean>(cookie === 'true');

  const closeDeclaimer = (): void => {
    setDeclaimerClosed(true);
    Cookies.set('disclaimer_viewed', 'true', { expires: 365 });
  };

  return {
    declaimerClosed,
    closeDeclaimer
  };
};

export function AppProvider ({ children }: any): JSX.Element {
  const app = useAppProvider();
  return <appContext.Provider value={app}>{children}</appContext.Provider>;
}
