import { addBreadcrumb } from '@sentry/react';

/**
 * Invia a Sentry un messaggio sul breadcrumb
 * per tracciare l'assenza di rete lato client.
 */
export const addConnectivityIssueBreadcrumb = (): void => {
  addBreadcrumb({
    category: 'network',
    message: 'Il client risulta non connesso alla rete',
    level: 'warning'
  });
};
