import React from 'react';
import { Box, Typography, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppContext } from 'context/App';

function Declaimer (): JSX.Element {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { closeDeclaimer } = useAppContext();

  return (
        <Box position="fixed" bottom={0} bgcolor="#e0e0e0">
            <Dialog open={true}>
                <DialogContent dividers>
                    <Typography variant="body1" component="p" mb={3}>
                        <strong>ChatGPT360 è uno strumento aziendale di GenerativeAI basato su ChatGPT di OpenAI</strong>, cioè un tool di elaborazione dei contenuti forniti dall’utilizzatore (<i>input</i>) che, grazie ad algoritmi ad apprendimento automatico, genera risposte analoghe a quelle rese dall’uomo (<i>output</i>).
                    </Typography>
                    <Typography variant="body1" component="p" mb={3}>
                        <strong>Gli utenti devono:</strong>
                    </Typography>
                    <Typography variant="body1" component="p" mb={3} px={2}>
                        <ol>
                            <li>
                                <strong>inserire come <i>input</i> soltanto contenuti che siano liberi da diritto d’autore o di cui si possieda idonea licenza</strong>; in alternativa, usare contenuti che rientrano nel dominio pubblico o rilasciati sotto “licenze creative commons”, così anche da evitare di divulgare informazioni, ottenute attraverso le elaborazioni effettuate da ChatGPT360, coperte dal diritto d’autore;
                            </li>
                            <li>
                                <strong>appurare in ogni caso di essere in possesso di tutti i diritti necessari prima di inserire contenuti, inclusi dati personali di terzi</strong> (come la loro immagine o altre informazioni a loro riferibili);
                            </li>
                            <li>
                                <strong>verificare con attenzione l’<i>output</i></strong>, prima di qualsiasi suo utilizzo, anche interno;
                            </li>
                            <li>
                                <strong>utilizzare lo strumento in modo sicuro, soltanto per finalità lavorative e nel rispetto della policy aziendale</strong> sull’utilizzo degli strumenti informatici, nonchè in conformità a quanto disposto da leggi e regolamenti tempo per tempo vigenti.
                            </li>
                        </ol>
                    </Typography>
                    <Typography variant="body1" component="p" mb={3}>
                        <strong>Agli utenti è fatto divieto di:</strong>
                    </Typography>
                    <Typography variant="body1" component="p" mb={3} px={2}>
                        <ol>
                            <li>
                                inserire contenuti di tipo offensivo, ingannevole, osceno, fraudolento, diffamatorio, calunnioso, discriminatorio, falso ed in qualsiasi modo non conforme alla normativa vigente o ai principi dell’ordine pubblico e del buon costume;
                            </li>
                            <li>
                                <strong>inserire contenuti coperti da vincoli di riservatezza</strong>;
                            </li>
                            <li>
                                <strong>inserire informazioni riservate relative al Gruppo o a società o collaboratori comunque collegati a Digital360</strong>;
                            </li>
                            <li>
                                <strong>inserire dati personali considerati “sensibili” (o categorie particolari di dati di cui all’art. 9 del GDPR)</strong>, cioè i dati relativi alla salute, dati genetici e biometrici, dati che rivelino l’origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, l’appartenenza sindacale o l’orientamento sessuale.
                            </li>
                        </ol>
                    </Typography>
                    <Typography variant="body1" component="p">
                    In ogni caso gli utenti devono rispettare quanto indicato nella policy aziendale in materia, anche in relazione all’uso appropriato dello strumento, al fine di mitigare adeguatamente i rischi intrinsechi dello strumento.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ px: 2, justifyContent: 'space-between' }}>
                    <Button sx={{ color: '#616161' }} variant="text" color="secondary" onClick={signOut}>
                        Non Accetto
                    </Button>
                    <Button onClick={closeDeclaimer}>
                        Accetto
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
  );
}

export default Declaimer;
