import { type UseFormRegister, useForm, type FieldErrors, type UseFormHandleSubmit } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import toast from 'react-hot-toast';
import { getFirstWordsFromString, sanitizeText } from 'helpers';

export interface PromptItemInput {
  id?: string | undefined
  title?: string
  text: string
};

const schema = yup.object({
  text: yup.string().trim().required('Campo obbligatorio.')
}).required();

const usePromptFormHook = ({
  prompt,
  dispatch,
  handleEditMode,
  handleLoad
}: {
  prompt: {
    id?: string
    title?: string
    text?: string
  }
  dispatch: React.Dispatch<any>
  handleEditMode: (id?: string | null, boolean?: boolean, openModal?: boolean) => void
  handleLoad: (load: boolean) => void
}): {
    register: UseFormRegister<PromptItemInput>
    handleSubmit: UseFormHandleSubmit<PromptItemInput>
    errors: FieldErrors
    onSubmit: (data: PromptItemInput) => Promise<void>
  } => {
  const { register, handleSubmit, formState: { errors } } = useForm<PromptItemInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: prompt?.id,
      title: typeof prompt?.title === 'string' ? sanitizeText(prompt.title) : '',
      text: typeof prompt?.text === 'string' ? sanitizeText(prompt.text) : ''
    }
  });
  const { user } = useAuthenticator((context) => [context.user]);

  const onSubmit = async (data: PromptItemInput): Promise<void> => {
    handleLoad(true);
    // inserimento/aggiornamento prompt
    // update
    if (data.id) {
      // Chiamata per l'update
      try {
        const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
        const title = data.title?.trim() ? data.title : getFirstWordsFromString(data.text, 10);
        const result = await fetch(`${process.env.REACT_APP_PROMPT_ENDPOINT}/prompts/${data.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ...data,
            title
          })
        });
        const json = await result.json();
        if (!json.result) {
          throw new Error(json.message);
        }
        const item = json.result;

        dispatch({
          type: 'UPDATE',
          data: {
            item: {
              ...data,
              text: item.text,
              title,
              date: item.date
            }
          }
        });
        handleLoad(false);
        handleEditMode(null, false, true);
        return;
      } catch (err) {
        console.error(err);
        toast.error('Qualcosa è andato storto, riprova più tardi', {
          id: 'update-prompt'
        });
        handleLoad(false);
        handleEditMode(null, false, false);
        captureException(err, {
          tags: {
            component: 'Prompts',
            action: 'updatePrompt',
            promptId: prompt.id,
            data: JSON.stringify(data)
          },
          user
        });
        return;
      }
    }
    // Chiamata api per l'inserimento
    try {
      const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      const title = data.title?.trim() ? data.title : getFirstWordsFromString(data.text, 10);

      const result = await fetch(`${process.env.REACT_APP_PROMPT_ENDPOINT}/prompts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          ...data,
          title
        })
      });
      const json = await result.json();
      if (!json.result) {
        throw new Error(json.message);
      }
      const item = json.result;

      dispatch({
        type: 'INSERT',
        data: {
          item: {
            ...data,
            text: item.text,
            title,
            id: item.id,
            date: item.date
          }
        }
      });
      handleLoad(false);
      handleEditMode(null, false, true);
    } catch (err) {
      console.error(err);
      toast.error('Qualcosa è andato storto, riprova più tardi', {
        id: 'insert-prompt'
      });
      handleLoad(false);
      handleEditMode(null, false, false);
      captureException(err, {
        tags: {
          component: 'Prompts',
          action: 'insertPrompt',
          data: JSON.stringify(data)
        },
        user
      });
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit
  };
};

export default usePromptFormHook;
