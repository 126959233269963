import { dialogcontent } from './dialogcontent';
import { dialogtitle } from './dialogtitle';
import { toolbar } from './toolbar';
import { dialogactions } from './dialogactions';
import { input } from './input';

const components = {
  ...dialogcontent,
  ...dialogtitle,
  ...toolbar,
  ...input,
  ...dialogactions
};

export { components };
