import { createTheme } from '@mui/material';
import { palette, typography, constants } from './globals';
import { components } from './components';

const theme = createTheme({
  constants,
  typography,
  palette,
  components
});

export default theme;
