import { palette, typography } from 'theme/globals';

export const input = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        '&:hover fieldset': {
          borderColor: `${palette.grey[300]} !important`
        },
        '&.Mui-focused fieldset': {
          borderColor: `${palette.primary.main} !important`
        }
      },
      input: {
        ...typography.body2,
        color: palette.grey[700],
        padding: '8px 16px'
      },
      notchedOutline: {
        borderColor: palette.grey[300]
      }
    }
  }
};
