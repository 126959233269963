import React, { useCallback, useState } from 'react';
import { Box, Button, Typography, type Theme } from '@mui/material';
import format from 'date-fns/format';
import it from 'date-fns/locale/it';
import { makeStyles } from 'tss-react/mui';
import { sanitizeText } from 'helpers';

export interface PromptItemType {
  id: string
  date: string
  title: string
  text: string
}

const useStyles: any = makeStyles()((theme: Theme) => ({
  buttonPrompt: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    padding: 0
  },
  buttonConfirmDelete: {
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    minWidth: 36,
    padding: 0
  }
}));

const PromptItem = ({
  item,
  deletePrompt,
  handleEditMode,
  setInputText
}: {
  item: PromptItemType
  deletePrompt: (id: string) => void
  handleEditMode: (id?: string | null, open?: boolean, openModal?: boolean) => void
  setInputText: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const { classes } = useStyles();

  /**
   * Elimina il prompt.
   */
  const deleteItem = useCallback(() => {
    deletePrompt(item.id);
  }, [item]);

  /**
   * Apre la finestra di modifica prompt.
   */
  const openItem = useCallback(() => {
    handleEditMode(item.id, true, true);
  }, [item]);

  /**
   * Copia il testo del prompt.
   */
  const copyText = useCallback(() => {
    setInputText(item.text);
    handleEditMode(null, false, false);
  }, [item]);

  return (
    <Box border={1} borderColor="primary.darker" bgcolor="primary.light" mb={1} px={2} py={1}>
      <Typography mb={0.5} variant="subtitle2" color="grey.700">{format(new Date(parseInt(item.date, 10)), 'dd MMM yyyy - HH:mm', { locale: it })}</Typography>
      <Typography mb={0.5} variant="body2" color="grey.700">{sanitizeText(item.title)}</Typography>
      <Box display="flex" justifyContent="space-between">
        {!canDelete
          ? <>
          <Box display="flex">
            <Button className={classes.buttonPrompt} color="error" onClick={() => { setCanDelete(true); }}>Elimina</Button>
            <Button className={classes.buttonPrompt} onClick={openItem}>Modifica</Button>
          </Box>
          <Button className={classes.buttonPrompt} onClick={copyText}>Seleziona</Button>
        </>
          : <>
          <Typography variant="h6" fontWeight={700} color="grey.700">Sei sicuro di voler eliminare il prompt?</Typography>
          <Box display="flex">
            <Button className={classes.buttonConfirmDelete} onClick={deleteItem}>SI</Button>
            <Button className={classes.buttonConfirmDelete} onClick={() => { setCanDelete(false); }}>NO</Button>
          </Box>
        </>
        }
      </Box>
    </Box>
  );
};

export default PromptItem;
