export const palette = {
  primary: {
    darker: '#7CC7FC',
    main: '#008AC9',
    light: '#D6EDFE'
  },
  error: {
    main: '#B00020'
  },
  grey: {
    700: '#616161',
    800: '#424242',
    300: '#E0E0E0'
  },
  warning: {
    main: '#616161'
  }
};
