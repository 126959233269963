import React, { useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton, Box } from '@mui/material';

const HeartIcon = ({
  onClick
}: {
  onClick: () => void
}): JSX.Element => {
  const [hover, setHover] = useState(false);

  return (
        <Box>
            <IconButton
                onClick={onClick}
                onMouseEnter={() => { setHover(true); }}
                onMouseLeave={() => { setHover(false); }}
                sx={{ p: 0 }}
            >
                {
                    hover
                      ? <FavoriteIcon color='error' fontSize='small' />
                      : <FavoriteBorderIcon fontSize='small' />
                }
            </IconButton>
        </Box>
  );
};

export default HeartIcon;
