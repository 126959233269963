import React from 'react';
import { TextField } from '@mui/material';
import { type FieldErrors, type UseFormRegister } from 'react-hook-form';
import { type PromptItemInput } from 'components/Prompts/hooks/usePromptFormHook';

const PromptForm = ({
  register,
  errors
}: {
  register: UseFormRegister<PromptItemInput>
  errors: FieldErrors<PromptItemInput>
}): JSX.Element => {
  return (
        <>
            <input
                type="hidden"
                {...register('id')}
            />
            <TextField
                fullWidth
                placeholder="Inserisci il titolo del prompt"
                margin="normal"
                variant="outlined"
                error={!!errors.title?.message}
                helperText={errors.title?.message}
                {...register('title')}
            />
            <TextField
                fullWidth
                multiline
                minRows={6}
                maxRows={6}
                placeholder="Inserisci il testo del prompt"
                margin="normal"
                variant="outlined"
                error={!!errors.text?.message}
                helperText={errors.text?.message}
                {...register('text')}
            />
        </>
  );
};

export default PromptForm;
