import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@emotion/react';

const CompanyElement = ({
  loginFunction,
  classNameBox,
  companies
}: {
  loginFunction: () => void
  classNameBox: string
  companies: [string]
}): JSX.Element => {
  const theme: any = useTheme();

  return (
    <Grid item xs={12} md={6} mb={3}>
    <Paper sx={{
      border: '1px solid #e0e0e0',
      marginLeft: '10px',
      marginRight: '10px'
    }}
      variant="outlined">
      <Box px={2} py={1} display="flex" flexWrap="wrap" component="ul">
        {companies.map((company, key) => <Box key={key} className={classNameBox} component="li">{company}</Box>)}
      </Box>
      <div style={{ border: '1px solid #e0e0e0' }} />
      <Button
        fullWidth
        onClick={loginFunction}
        startIcon={<img src="office.png" alt="office" />}
        sx={{
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          paddingLeft: '18px',
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
      >
        <Typography
          color="#424242"
          fontFamily="Titillium Web"
          fontWeight="600"
          fontSize="16px"
        >
          Accedi con Office365
        </Typography>
        <ChevronRightIcon htmlColor={theme.palette.primary.main} sx={{ marginLeft: 'auto' }}/>
      </Button>
    </Paper>
  </Grid>
  );
};

export default CompanyElement;
