import { Amplify } from 'aws-amplify';

const initConfig = () => {
  const redirect = process.env.REACT_APP_SENTRY_ENV === 'prod' ? 'https://chatgpt360.digital360.it' : 'https://chatgpt360.dev.ai.digital360.it';
  const awsConfig = {
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
      oauth: {
        domain: `chatgpt360.auth.${process.env.REACT_APP_REGION}.amazoncognito.com`,
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        // scope: ["openid", "https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/userinfo.profile"],
        redirectSignIn: redirect,
        redirectSignOut: redirect,
        responseType: 'code'
      }
    }
  };

  Amplify.configure(awsConfig);
};

export default { initConfig };
