export const toolbar = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        height: 61,
        minHeight: '61px !important',
        backgroundColor: '#fff'
      }
    }
  }
};
