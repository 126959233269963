import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, type Theme } from '@mui/material';
import PromptForm from '../PromptForm';
import usePromptFormHook from 'components/Prompts/hooks/usePromptFormHook';
import { makeStyles } from 'tss-react/mui';

const useStyles: any = makeStyles()((theme: Theme) => ({
  promptContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0,
    borderTop: 0
  }
}));

const PromptsInsertContent = ({
  handleEditMode,
  dispatch,
  promptTextFromChat,
  handleLoad
}: {
  handleEditMode: (id?: string | null, open?: boolean, openModal?: boolean) => void
  dispatch: React.Dispatch<any>
  promptTextFromChat?: string | undefined
  handleLoad: (load: boolean) => void
}): JSX.Element => {
  const { classes } = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    onSubmit
  } = usePromptFormHook({
    dispatch,
    handleEditMode,
    prompt: {
      text: promptTextFromChat
    },
    handleLoad
  });

  return (
        <>
          <DialogTitle sx={{ pb: 0 }}>
            Crea un nuovo prompt e aggiungilo ai tuoi preferiti
          </DialogTitle>
          <DialogContent className={classes.promptContainer} dividers>
            <PromptForm
              register={register}
              errors={errors}
            />
          </DialogContent>
          <DialogActions>
            <Button color="warning" autoFocus onClick={() => { handleEditMode(null, false, true); }}>
              Annulla
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>
              Aggiungi ai tuoi prompt
            </Button>
          </DialogActions>
        </>
  );
};

export default PromptsInsertContent;
